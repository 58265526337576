.css-1ajpnr9-MuiFormLabel-root-MuiInputLabel-root.MuiInputLabel-shrink {
  background-color: #212b36 !important;
}
.css-dgrlta.MuiInputLabel-shrink.Mui-focused,
.css-y1jvam-MuiFormLabel-root-MuiInputLabel-root,
.css-1ondv9x.MuiInputLabel-shrink.Mui-focused,
.css-10f68tr,
.css-d2bytn-MuiFormLabel-root-MuiInputLabel-root,
.css-d2bytn-MuiFormLabel-root-MuiInputLabel-root.MuiInputLabel-shrink.Mui-focused {
  background-color: #212b36 !important;
}

.MuiTypography-body2,
.MuiTypography-root,
.non-select,
.css-f9f4sa-MuiTypography-root {
  user-select: none !important;
}
.color-gray {
  color: #919eab !important;
}
.belowOneMinute {
  animation: heartbeat 1s infinite;
  transform-origin: center;

  &.belowFifteenSeconds {
    animation: heartbeat 0.3s infinite;
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}
// @media screen and (max-width: 1020px) {
//   .dashboard-card2 {
//     padding-top: 11rem !important;
//   }
// }
@media screen and (min-width: 1440px) {
  .dashboard-card2 {
    padding-top: 12rem !important;
  }
}
@media screen and (min-width: 1094px) {
  .dashboard-card2 {
    padding-top: 12rem !important;
  }
}
@media screen and (max-width: 1094px) {
  .dashboard-card2 {
    padding-top: 11rem !important;
  }
}
@media screen and (max-width: 1434px) {
  .dashboard-card2 {
    padding-top: 12rem !important;
  }
}
@media screen and (max-width: 1400px) {
  .dashboard-card2 {
    padding-top: 12rem !important;
  }
}
@media screen and (max-width: 1406px) {
  .dashboard-card2 {
    padding-top: 11rem !important;
  }
}
@media screen and (max-width: 1439px) {
  .dashboard-card2 {
    padding-top: 11rem !important;
  }
}
.card-img-product-icon {
  height: 20px;
}
.card-product-icon {
  height: 16px;
  object-fit: cover;
}
// .css-1jjzdoi-MuiStack-root {
//   display: none !important;
// }
.calender-div {
  background-color: #d6d6d6;
  border-radius: 50%;
  padding: 4px !important;
  width: 24px !important;
  height: 24px !important;
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 10px;
}
.market-text-dashboard {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: white !important;
}
.market-para-dashboard {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px;
  color: #a5a2a2 !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.market-btn-dashboard {
  border-radius: 10px !important;
  background-color: white !important;
  color: black !important;
  // display: inline-block !important;
  width: max-content !important;
}
.product-text-dashboard {
  color: #5be49b;
  font-size: 12px !important;
  font-weight: 700 !important;
  margin-bottom: 10px;
}
.card-1-img {
  margin-right: 1.5rem;
  width: 130px !important;
  height: 111px !important;
  object-fit: cover !important;
}
.product-count-dashboard {
  font-weight: 700 !important;
  font-size: 32px !important;
  color: white !important;
  position: relative;
  max-width: max-content;
  display: inline-block;
  .red-dot {
    position: absolute;
    top: 0px; /* Adjust this value to position the dot vertically */
    right: -5px; /* Adjust this value to position the dot horizontally */
    width: 6px;
    height: 6px;
    background-color: #ff5630;
    border-radius: 50%;
  }
}
.category {
  .css-7ywymc-MuiPaper-root-MuiAlert-root .MuiAlert-icon {
    color: #ff5630;
  }
}
.product-para-dashboard {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #ffffff;
}
.product-Container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.profile {
  background: linear-gradient(to bottom, rgb(10 9 9 / 80%), rgb(37 36 35 / 80%)),
    url(https://api-dev-minimal-v510.vercel.app/assets/images/cover/cover_4.jpg) !important;
}
// .singleView-Verified .css-q68xum-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
//   color: black !important;
// }
.cancelButtonModal {
  color: black !important;
  width: fit-content !important;
  margin-left: 10px !important;
  background-color: white !important;
  border: 1px solid #e3f0fd !important;
}
.cancelButtonModal:hover {
  background-color: lightgray !important; /* Change background color on hover */
}
.submitButtonModal {
  color: white !important;
  width: fit-content !important;
  margin-left: auto !important;
  background-color: #106e58 !important;
}
.submitButtonModal:hover {
  background-color: #0b4e3e !important; /* Change background color on hover */
}
.all-list-select-ui {
  .css-12geyt-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8.5px 14px !important;
  }
  .css-h8sl5y-MuiSvgIcon-root-MuiSelect-icon {
    color: black !important;
  }
  .css-sp20ms-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    color: black !important;
  }
  // production
  .css-12293dt.MuiSelect-select {
    color: black !important;
  }
  .css-12293dt.css-12293dt.css-12293dt,
  .css-1lgig84-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1lgig84-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1lgig84-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8.5px 14px !important;
  }
}
.toolbar-select-product {
  .css-12geyt-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
  .css-12293dt.css-12293dt.css-12293dt,
  label[data-shrink='false']
    + .MuiInputBase-formControl
    .css-1lgig84-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
  .css-65dyjx.css-65dyjx.css-65dyjx {
    padding: 11px 14px !important;
  }
  .css-1gir71h-MuiFormLabel-root-MuiInputLabel-root,
  .css-1x5sc7i,
  .css-1n77i8y-MuiFormLabel-root-MuiInputLabel-root,
  .css-10f68tr {
    top: -5px;
  }
}
.product-search {
  .css-1lblljk-MuiInputBase-input-MuiOutlinedInput-input,
  .css-1aeofd1,
  .css-1j6gnhn-MuiInputBase-input-MuiOutlinedInput-input,
  .css-od7qvm {
    padding: 12px 14px !important;
    align-items: center !important;
  }
}
.css-dgrlta.MuiInputLabel-shrink,
.css-1thzmb4,
.css-dgrlta.MuiInputLabel-shrink,
.css-1n77i8y-MuiFormLabel-root-MuiInputLabel-root {
  background-color: #212b36 !important;
}
.t-none {
  text-decoration: none !important;
}
.market-price-all-list-img {
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.check-box-text {
  font-size: 14px;
  color: #212b36;
  font-weight: 400;
}
.css-1yunev0-MuiStack-root {
  display: none !important;
}
.dashboard-cards-new {
  margin-bottom: 24px;
  padding: 1.5rem;
  .card-title {
    font-size: 14px;
    font-weight: 600;
    // color: #212b36;
  }
  .card-count {
    // color: #212b36;
    font-size: 32px;
    font-weight: 700;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .color-card-count {
  }
}
.color-card-count {
  color: #ffffff;
  font-size: 24px !important;
  font-weight: 700 !important;
}
.color-card-title {
  color: #a9a9a9;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.colored-dashboard-card {
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.color-card-img {
  height: 110px;
  width: 81px;
  margin-top: 2rem;
  opacity: 0.1;
}
.categoryImg {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.image-contain {
  .css-3j6ntj,
  MuiBox-root.css-3j6ntj {
    object-fit: contain !important;
  }
}
.main-head-search {
  .css-1lblljk-MuiInputBase-input-MuiOutlinedInput-input {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
.no-hover-effect {
  &:hover {
    color: #1a447b !important;
    background-color: #d9deff !important;
  }
}
.my-pdf-reader {
  .my-pdf-reader-iframe {
    // display: none !important;
  }
}
.search-allList {
  .css-1lblljk-MuiInputBase-input-MuiOutlinedInput-input,
  .css-1j6gnhn-MuiInputBase-input-MuiOutlinedInput-input,
  .css-1aeofd1,
  .css-od7qvm {
    padding: 11.5px 14px !important;
  }
}
.market-price {
  .css-12geyt-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 12.5px 14px;
  }
}
.input-market-price {
  .css-zyfrhy-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 14.5px 14px;
  }
}
.modal-check-box {
  .css-6aj2fz-MuiButtonBase-root-MuiCheckbox-root {
    padding: 9px;

    padding-left: 0px !important;
  }
}

.MuiFormLabel-asterisk.MuiInputLabel-asterisk,
.MuiFormLabel-asterisk.MuiInputLabel-asterisk.css-1ecnysf,
.MuiFormLabel-asterisk.MuiInputLabel-asterisk.css-4pefzv-MuiFormLabel-asterisk {
  color: red !important;
}
.hover-show-container {
  .hover-show {
    opacity: 0;
    visibility: hidden;
  }
  &:hover {
    .hover-show {
      opacity: 1;
      visibility: visible;
    }
  }
}
.product-all-list-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 15px;
}
.product-card-img {
  width: 100%;
  height: 164px;
  object-fit: cover;
  border-radius: 0px;
}
.badge-img-product {
  position: absolute;
  bottom: 0;
  left: -6px;
  width: 20px;
  height: 20px;
}
.product-issued-img {
  width: 52px;
  height: 52px;
  object-fit: cover;
  border-radius: 50%;
}
.priceContainer {
  position: absolute;
  top: 18px;
  left: 21px;
  background-color: #212b36;
  border-radius: 11px;
  padding: 4px;
  color: white;
}
.padding-0-container {
  padding: 0 10px !important;
}

.partner-register-container {
  // position: relative;
  padding: 0 25px;
  .partner-register {
    .register-view-container {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translateX(-50%);
      @media screen and (max-width: 996px) {
        top: 10%;
      }
    }
  }
  // .partner-register {
  //   margin-top: -30rem;
  //   width: 600px;
  //   margin-bottom: 100px;
  //   @media screen and (max-width: 1060px) {
  //     margin-top: -24rem;
  //   }
  //   @media screen and (max-width: 996px) {
  //     margin-top: -32rem;
  //   }
  //   @media screen and (max-width: 899px) {
  //     margin-top: -43rem;
  //   }
  //   @media screen and (max-width: 768px) {
  //     margin-top: -43rem;
  //     width: 100%;
  //   }

  //   @media screen and (max-width: 375px) {
  //     margin-top: -38rem;
  //   }
  // }
}

.small-select {
  .css-w8cakf-MuiFormLabel-root-MuiInputLabel-root {
    top: -5px;
  }
  .css-698ggw {
    height: 43px !important;
  }
  .css-em49yw .MuiOutlinedInput-root {
    height: 43px !important;
  }
  .css-1cw0187 {
    height: 43px !important;
  }
  .css-3wnyxb .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
    height: 43px !important;
  }
  .css-3wnyxb .MuiOutlinedInput-root {
    height: 43px !important;
  }
  .MuiAutocomplete-hasPopupIcon.css-em49yw .MuiOutlinedInput-root,
  .MuiAutocomplete-hasClearIcon.css-em49yw .MuiOutlinedInput-root {
    height: 43px !important;
  }
  .css-fq2zi1 {
    top: -5px;
  }
  .css-4odwut-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 43px;
  }
  .css-1p61z7h-MuiInputBase-root-MuiOutlinedInput-root,
  .css-1qv85oo-MuiInputBase-root-MuiOutlinedInput-root {
    height: 43px;
  }
  .MuiFormControl-root {
    .css-ssonly-MuiInputBase-root-MuiOutlinedInput-root {
      height: 43px !important;
    }
  }
}
.chart-dropdown {
  .css-12geyt-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    width: 35px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #f5f5f5;
  }
}
.color-danger {
  color: red !important;
}
.role-select-card {
  // background-color: #fff !important;
  transition: all ease-in-out 0.3s;
  &.inactive {
    &:hover {
      border-color: #dbe0e4 !important;
      transform: scale(1.03);
    }
  }
}
.step-wrapper {
  position: relative;
  min-height: 500px;
  margin: 0 5px;
  .stepper-button {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  .step {
    transition: all ease-in-out 0.6s;
    background: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -33rem;
    margin-top: 2rem;
    &.active {
      left: 0;
    }
    &.inactive {
    }
  }
}
#responsive-dialog-title {
  overflow: hidden;
}
.partner-create-form {
  width: 500px;
}
.modal-input {
  .css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 4.5px 14px !important;
  }
}
.custom-accordion {
  .css-kdwozy-MuiPaper-root-MuiAccordion-root.Mui-expanded {
    margin: 0 !important;
  }
}

.css-12vka4q-MuiFormLabel-root-MuiInputLabel-root,
.css-1jqq78o-placeholder {
  font-size: 13px;
}

.placeholder-sm {
  &::placeholder {
    font-size: 12px; // Adjust the font size as needed
  }
}
textarea,
input {
  font-size: 14px !important;
}
.select2-selection__control {
  border-radius: 8px !important;
  border: 1px solid #e8ecee !important;
}
.custom-select-ui {
  label {
    top: -6px;
  }
  .custom-select-ui-child {
    height: 40px;
  }
  .css-1x5orh4-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    height: 40px;
  }
}
// input::placeholder {
//   font-size: 12px !important;
// }

.box-shadow-unset {
  box-shadow: unset !important;
}
.partner-type-modal {
  .css-154lg22-MuiPaper-root-MuiDialog-paper {
    max-width: unset;
    min-width: 550px !important;
  }
}
.table-pd {
  .table-pd2 {
    .session-card-pd {
      width: 25px;
    }
  }
}
.session-table {
  td {
    vertical-align: top;
    padding: 8px;
  }
  tr {
    max-width: 10px;
  }
}

.cardPadding {
  .css-1j5s3cl {
    margin-bottom: 10px;
  }
  .css-48anut-MuiStack-root {
    margin-top: 15px;
  }
}
.card2 {
  .css-8145p1 {
    margin-bottom: 10px;
  }
  .css-48anut-MuiStack-root {
    margin-top: 15px;
  }
}
.card3 {
  .css-189e0nv {
    margin-bottom: 10px;
  }
  .css-1ludl2-MuiStack-root {
    margin-top: 15px;
  }
}
.single-view-taps {
  .single-view-tab {
    .css-heg063-MuiTabs-flexContainer {
      display: flex;
      flex-direction: column;
    }
    .css-6d3pg0-MuiTabs-indicator {
      display: none !important;
    }
  }
  #simple-tabpanel-0,
  #simple-tabpanel-1 {
    flex: 1;
  }
  .css-wdsdfe-MuiButtonBase-root-MuiTab-root.Mui-selected {
    border: 1px solid #009688;
    border-radius: 10px;
    color: #009688 !important;
  }
  .css-19kzrtu {
    padding: 0;
    padding-right: 4px !important;
  }
}
@media screen and (min-width: 1200px) {
  #sidebar-inActive {
    transition: all ease-in-out 0.3s;
    width: 65px;
    .css-mpr9kc-MuiPaper-root-MuiDrawer-paper {
      width: 65px;
    }
    .css-121b4uz-MuiListItemIcon-root {
      min-width: 48px;
    }
    .css-1y6sui9 {
      width: 65px !important;
    }
  }

  #sidebar-active {
    transition: all ease-in-out 0.3s;
    width: 280px;
    .css-mpr9kc-MuiPaper-root-MuiDrawer-paper {
      width: 280px;
    }
  }
}
@media screen and (max-width: 1200px) {
  #main-body-sidebar-active {
    padding-left: 30px;
    padding-right: 30px;
  }
}
#main-body-sidebar-inActive {
  .css-1oqqzyl-MuiContainer-root {
    @media (min-width: 1200px) {
      max-width: none;
    }
  }
}

.my-new-select .css-13cymwt-control {
  border-radius: 6px;
  min-height: 40px !important;
}
.my-new-select .css-1jqq78o-placeholder {
  color: #212b36;
}
.session-update-form .css-1ifcsjq-MuiFormLabel-root-MuiInputLabel-root {
  z-index: -1 !important;
}

.my-new-select .select2-selection__menu.css-1nmdiq5-menu {
  z-index: 99;
  position: absolute;
}
.next-button.css-1umjv0b-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: #fff !important;
}
.next-button.css-fkxtkf.Mui-disabled {
  color: #fff !important;
}
.div-chip {
  .div-delete {
    color: #cc3c1b !important;
    opacity: 1 !important;
    // &:hover{
    //   opacity: 1 !important;
    //   color: #CC3C1B !important;
    // }
  }
}

.react-player-video {
  iframe {
    border-radius: 12px;
    @media screen and (max-width: 2560px) {
      width: 1000px;
      height: 550px;
    }
    @media screen and (max-width: 1440px) {
      width: 720px;
      height: 450px;
    }
    @media screen and (max-width: 1024px) {
      width: 650px;
      height: 400px;
    }
    @media screen and (max-width: 768px) {
      width: 500px;
      height: 300px;
    }
  }
}
// .btn-expand:hover{
//   background-color: white;
//   color: currentColor;
// }
